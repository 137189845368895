import React from "react";
import Layout from "../components/layout";
import ContatoBanner from "../images/BannerExecDeObras.jpg";
import SEO from "../components/seo";

function Orcamento() {
  return (
    <Layout>
      <SEO
        keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
        title="Contato"
      />

      <section
        style={{
          height: "300px",
          backgroundImage: `linear-gradient(to right, hsla(0, 0%, 0%, 1), hsla(0, 0%, 0%, 0.3)), url(${ContatoBanner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        <h1 className="font-bold text-white ml-8 mt-24 uppercase base-title-golden">
          Contato
        </h1>
      </section>

      <div className="lg:flex py-24 md:mx-10">
        <div className="mx-2 md:mx-4 w-full lg:w-3/5">
          <h2 className="font-bold base-title-golden mb-6">Como chegar</h2>
          <iframe
            width="100%"
            title="SoftinLocationMap"
            height="360"
            frameBorder="0"
            style={{ border: 0, display: "block" }}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d890.8844485192174!2d-48.684796870789185!3d-26.72720919895048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94d8d5022915f21f%3A0x50019f50b84dc2d5!2sR.%20das%20Orqu%C3%ADdeas%2C%20321%2C%20Balne%C3%A1rio%20Pi%C3%A7arras%20-%20SC%2C%2088390-000!5e0!3m2!1spt-BR!2sbr!4v1585421971805!5m2!1spt-BR!2sbr"
            allowFullScreen=""
          />
        </div>
        <div className="mx-2 md:mx-8 mt-5 lg:mt-0 lg:w-1/2 xl:w-2/4">
          <h2 className="font-bold base-title-golden mb-6">Base operacional</h2>
          <div className="flex mb-2">
            <box-icon name="map" color="#f9c000" />
            <h3 className="flex text-base items-center ml-3">
              Rua 4100, 321 - Itacolomi, Piçarras/SC
            </h3>
          </div>
          <div className="flex mb-2">
            <box-icon name="whatsapp" color="#f9c000" type="logo" />
            <h3 className="flex text-base items-center ml-3">(47) 98897-0668</h3>
          </div>
          <div className="flex mb-2">
            <box-icon name="envelope" color="#f9c000" />
            <h3 className="flex text-base items-center ml-3">
              comercial@goldentecnologia.ind.br
            </h3>
          </div>
        </div>
      </div>
      <div className="lg:flex pb-20 md:mx-10">
        <div className="mx-2 md:mx-4 w-full lg:w-3/5">
          <h2 className="font-bold base-title-golden mb-6">Como chegar</h2>
          <iframe
            width="100%"
            title="SoftinLocationMap"
            height="360"
            frameBorder="0"
            style={{ border: 0, display: "block" }}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3577.506017427641!2d-48.83763968543677!3d-26.27769117334581!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94deb1d5f0f0eb95%3A0x8596345fc0f9aae5!2sR.%20Iriri%C3%BA%2C%20847%20-%20Sagua%C3%A7u%2C%20Joinville%20-%20SC%2C%2089221-515!5e0!3m2!1spt-BR!2sbr!4v1586998059025!5m2!1spt-BR!2sbr"
            allowFullScreen=""
          />
        </div>
        <div className="mx-2 md:mx-8 mt-5 lg:mt-0 lg:w-1/2 xl:w-2/4">
          <h2 className="font-bold base-title-golden mb-6">
            Centro administrativo
          </h2>
          <div className="flex mb-2">
            <box-icon name="map" color="#f9c000" />
            <h3 className="flex text-base items-center ml-3">
              Rua Iririú, 847 - Iririú, Joinville/SC
            </h3>
          </div>
          <div className="flex mb-2">
            <box-icon name="phone" color="#f9c000" />
            <h3 className="flex text-base items-center ml-3">(47) 3170-1115</h3>
          </div>          
          <div className="flex mb-2">
            <box-icon name="envelope" color="#f9c000" />
            <h3 className="flex text-base items-center ml-3">
              comercial@goldentecnologia.ind.br
            </h3>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Orcamento;
